var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "table table-sm table-borderless"
  }, [_c('thead', [_c('tr', [_c('th'), _c('th', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.period.startDate.year()))]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.period.startDateMinusYear.year()))]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v("Variance")])])]), _c('tbody', [_c('tr', [_c('td', {
    staticClass: "font-w600"
  }, [_vm._v("Day Units")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.data.dayConsumption, 2)))]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.prevData.dayConsumption, 2)))]), _c('td', {
    staticClass: "text-right"
  }, [_c('PctDiff', {
    attrs: {
      "value": _vm.data.dayConsumption,
      "prev-value": _vm.prevData.dayConsumption,
      "comparison-period": "year",
      "hide-text": ""
    }
  })], 1)]), _c('tr', [_c('td', {
    staticClass: "font-w600"
  }, [_vm._v("Night Units")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.data.nightConsumption, 2)))]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.prevData.nightConsumption, 2)))]), _c('td', {
    staticClass: "text-right"
  }, [_c('PctDiff', {
    attrs: {
      "value": _vm.data.nightConsumption,
      "prev-value": _vm.prevData.nightConsumption,
      "comparison-period": "year",
      "hide-text": ""
    }
  })], 1)]), _c('tr', [_c('td', {
    staticClass: "font-w600"
  }, [_vm._v("Other Units")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("N/A")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("N/A")]), _c('td', {
    staticClass: "text-right"
  }, [_c('PctDiff', {
    attrs: {
      "value": 0,
      "prev-value": 0,
      "comparison-period": "year",
      "hide-text": ""
    }
  })], 1)]), _c('tr', [_c('td', {
    staticClass: "font-w600"
  }, [_vm._v("Available Capacity")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("N/A")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("N/A")]), _c('td', {
    staticClass: "text-right"
  }, [_c('PctDiff', {
    attrs: {
      "value": 0,
      "prev-value": 0,
      "comparison-period": "year",
      "hide-text": ""
    }
  })], 1)]), _c('tr', [_c('td', {
    staticClass: "font-w600"
  }, [_vm._v("Maximum Capacity")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.data.maxDemand, 2)))]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.prevData.maxDemand, 2)))]), _c('td', {
    staticClass: "text-right"
  }, [_c('PctDiff', {
    attrs: {
      "value": _vm.data.maxDemand,
      "prev-value": _vm.prevData.maxDemand,
      "comparison-period": "year",
      "hide-text": ""
    }
  })], 1)]), _c('tr', [_c('td', {
    staticClass: "font-w600"
  }, [_vm._v("Load Factor")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.data.peakLoad, 2)) + "%")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.prevData.peakLoad, 2)) + "%")]), _c('td', {
    staticClass: "text-right"
  }, [_c('PctDiff', {
    attrs: {
      "value": _vm.data.peakLoad,
      "prev-value": _vm.prevData.peakLoad,
      "comparison-period": "year",
      "hide-text": ""
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }